import useRoutes from './routes/routes';

import './index.css';
import './App.css';

const App = () => {
  const routes = useRoutes();

  return <div className='App'>{routes}</div>;
};

export default App;
