import { Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '../components/PrivateRoute';
import Main from '../pages/Main';
import Login from '../pages/Login';

export const useRoutes = () => {
  return (
    <Routes>
      <Route path='/login' element={<Login />} />

      <Route element={<PrivateRoute />}>
        <Route path='/' element={<Main />} />
      </Route>
    </Routes>
  );
};

export default useRoutes;
