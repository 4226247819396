import { useEffect } from 'react';
import fileDownload from 'js-file-download';

import { getRequestFile, deleteRequest } from '../utils/axios';

const Main = () => {
  const getCsv = async () => {
    const result = await getRequestFile('file', { responseType: 'blob' }, {});

    if (result) {
      const filename = result.headers['content-disposition']
        .split('filename=')[1]
        .split('.')[0];
      const extension = result.headers['content-disposition']
        .split('.')[1]
        .split(';')[0];

      await fileDownload(result.data, `${filename}.${extension}`);

      await deleteRequest(`file/${filename}.${extension}`);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.location.reload();
    }, 600000);
  });

  return (
    <div className='main'>
      <h1>Statistic</h1>
      <button type='button' onClick={getCsv}>
        Get CSV
      </button>
    </div>
  );
};

export default Main;
