export const Config = {
  HEADERS: {
    'Content-Type': 'application/json; charset=utf-8',
  },
  MULTIPART_HEADERS: {
    'Content-Type': 'multipart/form-data; charset= utf-8; boundary=---sad',
  },
};

export const SERVER =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:5000'
    : 'https://roche.onpoint.ru';
